[class*='emoji'] {
    font-family: var(--font-emojis-cbdt);
}

/* Safari Only CSS here */
_::-webkit-full-page-media,
_:future,
:root [class*='emoji'] {
    font-family: var(--font-emojis-sbix);
}

/* Chrome Only CSS here */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .emoji {
        font-family: var(--font-emojis-svg);
    }
}

/* Firefox Only CSS here */
@-moz-document url-prefix() {
    .emoji {
        font-family: var(--font-emojis-svg);
    }
}

@font-face {
font-family: '__svgFont_d78c7f';
src: url(https://static.gitbook.com/_next/static/media/79ec87d3cdff1fa5-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__svgFont_Fallback_d78c7f';src: local("Arial");ascent-override: 83.01%;descent-override: 14.65%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_d78c7f {font-family: '__svgFont_d78c7f', '__svgFont_Fallback_d78c7f'
}.__variable_d78c7f {--font-emojis-svg: '__svgFont_d78c7f', '__svgFont_Fallback_d78c7f'
}

@font-face {
font-family: '__sbixFont_0034ec';
src: url(https://static.gitbook.com/_next/static/media/8c5a8b58a82efc8e-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__sbixFont_Fallback_0034ec';src: local("Arial");ascent-override: 83.01%;descent-override: 14.65%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_0034ec {font-family: '__sbixFont_0034ec', '__sbixFont_Fallback_0034ec'
}.__variable_0034ec {--font-emojis-sbix: '__sbixFont_0034ec', '__sbixFont_Fallback_0034ec'
}

@font-face {
font-family: '__cbdtFont_774aa7';
src: url(https://static.gitbook.com/_next/static/media/332370fdb30dcf2a-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__cbdtFont_Fallback_774aa7';src: local("Arial");ascent-override: 83.01%;descent-override: 14.65%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_774aa7 {font-family: '__cbdtFont_774aa7', '__cbdtFont_Fallback_774aa7'
}.__variable_774aa7 {--font-emojis-cbdt: '__cbdtFont_774aa7', '__cbdtFont_Fallback_774aa7'
}

.headerLinks_containerHeaderlinks__GUgiv {
    container-type: inline-size;
    container-name: headerlinks;
}

.headerLinks_containerHeaderlinks__GUgiv > * {
    display: none;
}

@container headerlinks ( width > 150px ) {
    .headerLinks_containerHeaderlinks__GUgiv > *:nth-child(1) {
        display: flex;
    }
}
@container headerlinks ( width > 300px ) {
    .headerLinks_containerHeaderlinks__GUgiv > *:nth-child(2) {
        display: flex;
    }
}
@container headerlinks ( width > 450px ) {
    .headerLinks_containerHeaderlinks__GUgiv > *:nth-child(3) {
        display: flex;
    }
}
@container headerlinks ( width > 600px ) {
    .headerLinks_containerHeaderlinks__GUgiv > *:nth-child(4) {
        display: flex;
    }
}
@container headerlinks ( width > 750px ) {
    .headerLinks_containerHeaderlinks__GUgiv > *:nth-child(5) {
        display: flex;
    }
}
@container headerlinks ( width > 900px ) {
    .headerLinks_containerHeaderlinks__GUgiv > *:nth-child(n + 5) {
        display: flex;
    }
}

html:has(.ZoomImage_zoomModal__VzJS3) {
    overflow: hidden;
}

.ZoomImage_zoomImg__teSyL {
    cursor: zoom-in;
}

.ZoomImage_zoomImageActive__C33dt {
    view-transition-name: zoom-image;
}

.ZoomImage_zoomModal__VzJS3 {
}

.ZoomImage_zoomModal__VzJS3 img {
    view-transition-name: zoom-image;
    cursor: zoom-out;
}

